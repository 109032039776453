var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "breadcrumb" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName2,
              callback: function ($$v) {
                _vm.activeName2 = $$v
              },
              expression: "activeName2",
            },
          },
          [
            _vm.authority.tabs["parameters"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "业务参数配置", name: "first" } },
                  [
                    _c("business-manage", {
                      ref: "business",
                      attrs: {
                        authority: _vm.authority.tabs["parameters"],
                        details: _vm.details,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["channel"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "通道管理", name: "second" } },
                  [
                    _c("channe-manage", {
                      ref: "channe",
                      attrs: {
                        authority: _vm.authority.tabs["channel"],
                        details: _vm.details,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["calculate"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "计费规则", name: "third" } },
                  [
                    _c("charge-rule", {
                      ref: "charge",
                      attrs: {
                        authority: _vm.authority.tabs["calculate"],
                        details: _vm.details,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }